import { ERC20Token, WETH9, ChainId, WNATIVE, Native } from '@pancakeswap/sdk';
import { chains } from '@icecreamswap/constants';

// src/common.ts
var USD = chains.reduce((acc, chain) => {
  if (!chain.stableToken)
    return acc;
  return { ...acc, [chain.id]: new ERC20Token(
    chain.id,
    chain.stableToken.address,
    chain.stableToken.decimals,
    chain.stableToken.symbol,
    chain.stableToken.name
  ) };
}, {});
var STABLE_COIN = USD;
var ICE = chains.reduce((acc, chain) => {
  if (!chain.iceAddress)
    return acc;
  return { ...acc, [chain.id]: new ERC20Token(
    chain.id,
    chain.iceAddress,
    18,
    "ROBIN",
    "Robin",
    "https://obsidian.finance"
  ) };
}, {});

// ../utils/enumValues.ts
function* enumValues(enumObj) {
  let isStringEnum = true;
  for (const property in enumObj) {
    if (typeof enumObj[property] === "number") {
      isStringEnum = false;
      break;
    }
  }
  for (const property in enumObj) {
    if (isStringEnum || typeof enumObj[property] === "number") {
      yield enumObj[property];
    }
  }
}

// src/helpers.ts
var createEmptyList = () => {
  const list = {};
  for (const chainId of enumValues(ChainId)) {
    list[chainId] = {};
  }
  return list;
};
var EMPTY_LIST = createEmptyList();
function serializeTokens(unserializedTokens) {
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: unserializedTokens[key].serialize };
  }, {});
  return serializedTokens;
}
function unwrappedToken(token) {
  if (token && token.equals(WNATIVE[token.chainId]))
    return Native.onChain(token.chainId);
  return token;
}
var cronosTokens = {
  wcro: WETH9[ChainId.CRONOS],
  ice: ICE[ChainId.CRONOS],
  robin: new ERC20Token(ChainId.CRONOS, "0x664E4b17EA045fE92868821F3eE0a76a5dB38166", 18, "ROBIN", "Robin", "https://robincronos.com"),
  usdt: new ERC20Token(ChainId.CRONOS, "0x66e428c3f67a68878562e79A0234c1F83c208770", 6, "USDT", "Tether USD"),
  mery: new ERC20Token(ChainId.CRONOS, "0x3b41B27E74Dd366CE27cB389dc7877D4e1516d4d", 18, "MERY", "Mistery"),
  usdc: new ERC20Token(ChainId.CRONOS, "0xc21223249CA28397B4B6541dfFaEcC539BfF0c59", 6, "USDC", "USD Coin"),
  retrib: new ERC20Token(ChainId.CRONOS, "0x6ad9893eB92F4916f703f660410a454f6Acc3FE1", 18, "RETRIB", "RETRIBUTION", "https://retribution-finance.com"),
  retribLp: new ERC20Token(ChainId.CRONOS, "0x39017CDBC84101d737D2410d67FCA3AC0783B90f", 18, "RETRIB-LP", "RETRIBUTION", "https://retribution-finance.com"),
  bobz: new ERC20Token(ChainId.CRONOS, "0xBCfE5afF53fb269969725c12e5b9C3ab18B3B66c", 18, "BOBz", "Bobz", "https://bob-adventures.netlify.app"),
  crora: new ERC20Token(ChainId.CRONOS, "0x6932D52658687CBE9DAf357cdc5F911FE4F18950", 18, "CRORA", "CronosRebelAlliance"),
  croraLp: new ERC20Token(ChainId.CRONOS, "0xba68AbB6d60Ee3EEFD6Da778609f00C0Ee86Fb11", 18, "CRORA-LP", "CRORA"),
  yoshi: new ERC20Token(ChainId.CRONOS, "0x4594104d43cEC6F5c8C6631294d5582D0d67EdE7", 18, "Baby Ryoshi", "YOSHI"),
  yoshiLp: new ERC20Token(ChainId.CRONOS, "0xa7C9D401cFc854d3E412be913e68D049Ab84E155", 18, "YOSHI-LP", "YOSHI"),
  reaper: new ERC20Token(ChainId.CRONOS, "0x73712A912AE5e39634046F4409Ef57eac34E96BA", 18, "REAPER", "REAPER"),
  faf: new ERC20Token(ChainId.CRONOS, "0xBf004bD24b13f79aD2861290fe3507599d879845", 18, "FAF", "FAF"),
  pmr: new ERC20Token(ChainId.CRONOS, "0xD074524C6C412848da63E9251171a9bfD6a084B3", 18, "PMR", "Puush Mery"),
  pmrLp: new ERC20Token(ChainId.CRONOS, "0xFe3FCeeC8e5E2C3c197727a52dFE50a102F32fAe", 18, "PMR-ROBIN LP", "PMR/ROBIN"),
  waifu: new ERC20Token(ChainId.CRONOS, "0x8757af5aAB32bbDC5d19c8Ada05C70efBb953417", 18, "WAIFU", "WAIFU", "https://x.com/CROwaifu"),
  wario: new ERC20Token(ChainId.CRONOS, "0xd7BF423FfE56Bc1a831080aAF8e9C8bF2f949336", 18, "WARIO", "WARIO"),
  warioLp: new ERC20Token(ChainId.CRONOS, "0xbc95dd42ba4FA1AB074d447227178b1314750211", 18, "WARIO-CRO-LP", "WARIO", "https://x.com/RyoshiBaby"),
  clg: new ERC20Token(ChainId.CRONOS, "0x08d9cb5100C306C2909B63415d7ff05268633b41", 18, "CLG", "Cronos Legends", "https://x.com/CronosLegends"),
  cara: new ERC20Token(ChainId.CRONOS, "0x3Caf0136302440Ebb68f41D6c57b7bEbDE9E12ca", 18, "CARA", "Cara", "https://x.com/CronosCara"),
  fafLp: new ERC20Token(ChainId.CRONOS, "0xd2e90fb78dc14939829fb38A4E1d6A95a3b1696F", 18, "FAF-ROBIN LP", "FAF"),
  cpb: new ERC20Token(ChainId.CRONOS, "0x22EAe794BF266f7D46ddbF25ab3c7ba01E6Bc081", 18, "CPB", "Cosplay Bears", "https://ninetiestoken.xyz"),
  lion: new ERC20Token(ChainId.CRONOS, "0x88Ed62A68e1eaABdfc8bA96e10a68561Ab9579b5", 18, "LION", "Lionel The Lion", "https://cronoslionel.com/"),
  peyes: new ERC20Token(ChainId.CRONOS, "0xe9A4c3aba1075E1470EBe3007db11132905c9bdC", 18, "PEYES", "Pepes Eyes"),
  bullish: new ERC20Token(ChainId.CRONOS, "0x477c46076EBB6585370ad96f6090f04a7195851a", 18, "Bullish", "Kris Wif Trump"),
  kitty: new ERC20Token(ChainId.CRONOS, "0x4d7c922D6C12CfbF5BC85F56c9ccB1F61f49bf61", 18, "KITTY", "KitCoin", "https://www.kitcoin.io/"),
  bmn: new ERC20Token(ChainId.CRONOS, "0xCb49dd81da680ed11d10809B51d5D5D8e36F9B6D", 18, "BMN", "BLACK MOON"),
  chode: new ERC20Token(ChainId.CRONOS, "0x0F1E83378baa83e000492d6d1eB09CBC9E4628E6", 18, "CHODE", "CHODE"),
  krazy: new ERC20Token(ChainId.CRONOS, "0x91e8BB36c3ec72a24A15455a077ECA20A701bB84", 18, "KRAZY", "KRAZY")
};
var cronoszkTokens = {
  wcro: WETH9[ChainId.CRONOSZK],
  //ice: ICE[ChainId.CRONOSZK],
  ice: WETH9[ChainId.CRONOSZK],
  usdt: new ERC20Token(ChainId.CRONOSZK, "0x5b91e29Ae5A71d9052620Acb813d5aC25eC7a4A2", 18, "vUSD", "Veno USD"),
  vusd: new ERC20Token(ChainId.CRONOSZK, "0x5b91e29Ae5A71d9052620Acb813d5aC25eC7a4A2", 18, "vUSD", "Veno USD"),
  lion: new ERC20Token(ChainId.CRONOSZK, "0x03E094dE4DB72A8752B684C232816D1EE3D04Ff4", 18, "LION", "Lionel The Lion", "https://cronoslionel.com/")
};

// src/allTokens.ts
var allTokens = {
  [ChainId.CRONOS]: cronosTokens,
  [ChainId.CRONOSZK]: cronoszkTokens
};

// src/getTokensByChain.ts
function getTokensByChain(chainId) {
  if (!chainId) {
    return [];
  }
  const tokenMap = allTokens[chainId];
  return Object.values(tokenMap);
}
function getTokenByAddress(chainId, address) {
  const tokens = getTokensByChain(chainId);
  return tokens.find((token) => token.address.toLowerCase() === address.toLowerCase());
}
var bitgertTokens = {
  wbrise: WETH9[ChainId.BITGERT],
  ice: ICE[ChainId.BITGERT],
  sps: new ERC20Token(ChainId.BITGERT, "0x1633b7157e7638C4d6593436111Bf125Ee74703F", 18, "SPS", "Splinterlands Token", "https://splinterlands.com"),
  sphynx: new ERC20Token(ChainId.BITGERT, "0x0e11DCE06eF2FeD6f78CEF5144F970E1184b4298", 18, "SPHYNX", "Sphynx BRISE"),
  bpad: new ERC20Token(ChainId.BITGERT, "0x71946a5C9dA7C95ee804a9BE561EC15A3F286A7D", 8, "BPAD", "Brisepad"),
  broge: new ERC20Token(ChainId.BITGERT, "0x41c5ae56681Fb19334eCF7d914919805DaE2Ec8f", 18, "BROGE", "BROGE"),
  brzilla: new ERC20Token(ChainId.BITGERT, "0x9b8535Dd9281e48484725bC9Eb6Ed2f66CEA2a36", 18, "BRZILLA", "BriseZilla"),
  btxt: new ERC20Token(ChainId.BITGERT, "0x1A8a039007186d7640C1D7Cd7c2606e333D04e03", 18, "BTXT", "BitsXT"),
  eltg: new ERC20Token(ChainId.BITGERT, "0xb860eCD8400600c13342a751408737235E177077", 9, "ELTG", "Graphen"),
  evo: new ERC20Token(ChainId.BITGERT, "0x267Ae4bA9CE5ef3c87629812596b0D89EcBD81dD", 18, "EVO", "EVO"),
  map: new ERC20Token(ChainId.BITGERT, "0x6D347fdCb302a5879545E01EceE7A176db23dCDa", 2, "MAP", "4D Twin Maps"),
  miidas: new ERC20Token(ChainId.BITGERT, "0x5B534A2Df329195Fd7e5c9AcA1D9ffbdA14A4963", 6, "Miidas", "Miidas NFT", "https://brise.miidas.com/"),
  mir: new ERC20Token(ChainId.BITGERT, "0x2468dad471fA7E03d8029F057cc41742F017D53d", 18, "MIR", "Mix Reality"),
  numi: new ERC20Token(ChainId.BITGERT, "0x6718e47e74497d1564EE76d832309144b83Ef8E8", 18, "NUMI", "Numitor"),
  omnia: new ERC20Token(ChainId.BITGERT, "0x5d4685c2C75581C67b9D6292A065a767bC214681", 8, "OMNIA", "OmniaVerse"),
  prds: new ERC20Token(ChainId.BITGERT, "0x31226B28add9062c5064a9Bd35eA155F323C6ca6", 9, "PRDS", "Brise Paradise"),
  rluna: new ERC20Token(ChainId.BITGERT, "0x6660A7AF57fAE695D4a10D645088aBA9fb547728", 18, "RLUNA", "Rise Luna"),
  vef: new ERC20Token(ChainId.BITGERT, "0xD6447d2fA919811c41a064bDbdaB1E281F8de9B2", 18, "VEF", "Vefi Ecosystem Token"),
  wmf: new ERC20Token(ChainId.BITGERT, "0xc89fcd3E1CF5A355fc41E160d18BAC5f624610D4", 18, "WMF", "Whale Maker Fund"),
  yogo: new ERC20Token(ChainId.BITGERT, "0xB361D5953e21Cfde5CD62B89FDf40bc21903A6bb", 18, "YOGO", "YOGO Token"),
  ypc: new ERC20Token(ChainId.BITGERT, "0x11203a00a9134Db8586381C4B2fca0816476b3FD", 18, "YPC", "Young Parrot"),
  tokyo: new ERC20Token(ChainId.BITGERT, "0x38EA4741d100cAe9700f66B194777F31919142Ee", 9, "$Tokyo", "Metaverse City Tokyo"),
  usdc: new ERC20Token(ChainId.BITGERT, "0x765277EebeCA2e31912C9946eAe1021199B39C61", 6, "USDCm", "USD Coin Multichain"),
  usdt: new ERC20Token(ChainId.BITGERT, "0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D", 6, "USDTm", "Tether USD Multichain"),
  wolf: new ERC20Token(ChainId.BITGERT, "0x4Fb3DBF9111169ff60fFB8a7be1c6Fd3D4E417bC", 19, "WOLF", "Alpha Trades"),
  abr: new ERC20Token(ChainId.BITGERT, "0x9F7Bb6E8386ac9ad5e944d66fBa80F3F7231FA94", 9, "ABR", "AIBRA"),
  baskom: new ERC20Token(ChainId.BITGERT, "0x6cd08bE8Aa9B705Ca86B4923B1784C0eE06E5220", 9, "BASKOM", "Brisecom"),
  lung: new ERC20Token(ChainId.BITGERT, "0xc3b730dD10A7e9A69204bDf6cb5A426e4f1F09E3", 18, "LUNG", "LunaGens"),
  usdti: new ERC20Token(ChainId.BITGERT, "0xC7E6d7E08A89209F02af47965337714153c529F0", 18, "USDTi", "Tether USD IcecreamSwap"),
  $3dc: new ERC20Token(ChainId.BITGERT, "0x5feDA75eaB27814Cba0694C9711F7d4abEa5b0b5", 8, "$3DC", "3D City"),
  darrival: new ERC20Token(ChainId.BITGERT, "0xeB18A16A08530b811523fA49310319809ac4c979", 9, "DRV", "Darrival"),
  arco: new ERC20Token(ChainId.BITGERT, "0xbfa6394b9898566652276f1Fb40d65e75787D66f", 9, "ARCO", "Ardiansyah Crypto"),
  ethi: new ERC20Token(ChainId.BITGERT, "0xeA61Dc96F105469522d39cBF7bD59b42393678F7", 18, "ETHi", "Ether IcecreamSwap"),
  dogei: new ERC20Token(ChainId.BITGERT, "0x46a8E16dB8Bb241618873bCA21Ef02F120EA4125", 18, "DOGEi", "Dogecoin IcecreamSwap"),
  bnbi: new ERC20Token(ChainId.BITGERT, "0x74446a7418BFbFCDe3F1f6bCaFFA097d050a6dD8", 18, "BNBi", "BNB IcecreamSwap"),
  shibi: new ERC20Token(ChainId.BITGERT, "0xADF3051f6fbC1f42ee20B2eDb47EA7f6CcaBe978", 18, "SHIBi", "Shiba Inu IcecreamSwap"),
  daii: new ERC20Token(ChainId.BITGERT, "0x71Ef0A490E53Cc177F640169b0347Be4d0f23cc9", 18, "DAIi", "DAI IcecreamSwap"),
  usdci: new ERC20Token(ChainId.BITGERT, "0xaEdD3Ff7b9Fc5fc4e44d140b80f0B1C7FdB6102c", 18, "USDCi", "USD Coin IcecreamSwap"),
  busdi: new ERC20Token(ChainId.BITGERT, "0xd0CE781960c6356A7175988751bfC8d7cd28EA60", 18, "BUSDi", "BUSD IcecreamSwap"),
  bambi: new ERC20Token(ChainId.BITGERT, "0x6C8Af2c462c4D3A487d005A6FeFBcdc95a7F5dfe", 18, "WBMBIV2", "WrappedBambiTokenV2"),
  bbi: new ERC20Token(ChainId.BITGERT, "0x43Fd2FAfa5cfcCb66d03061b59a25f02ec194d1B", 9, "BBI", "BubbleBassInu")
};
var dogechainTokens = {
  wdoge: WETH9[ChainId.DOGE],
  ice: ICE[ChainId.DOGE],
  usdt: new ERC20Token(ChainId.DOGE, "0xD2683b22287E63D22928CBe4514003a92507f474", 18, "USDT", "Tether USD")
};
var fuseTokens = {
  wfuse: WETH9[ChainId.FUSE],
  ice: ICE[ChainId.FUSE],
  doge: new ERC20Token(ChainId.FUSE, "0x12AA82525DEfF84777fa78578A68ceB854A85f43", 18, "DOGE", "DogeCoin"),
  shiba: new ERC20Token(ChainId.FUSE, "0x8687cD1d02A28098571067ddB18F33fEF667C929", 18, "SHIB", "Shiba Inu"),
  usdt: new ERC20Token(ChainId.FUSE, "0xFaDbBF8Ce7D5b7041bE672561bbA99f79c532e10", 18, "USDT", "Tether USD")
};
var xdcTokens = {
  wxdc: WETH9[ChainId.XDC],
  ice: ICE[ChainId.XDC],
  usdt: new ERC20Token(ChainId.XDC, "0xc57F0eb99363e747D637B17BBdB4e1AB85e60631", 18, "USDT", "Tether USD"),
  usdc: new ERC20Token(ChainId.XDC, "0xB25cB6a275a8D6a613228FB161eB3627b50EB696", 18, "USDC", "USD Coin"),
  btcx: new ERC20Token(ChainId.XDC, "0xb6E57fBB2D44092f75d9da2769FFc788ce931320", 8, "BTCx", "BitcoinX"),
  usplus: new ERC20Token(ChainId.XDC, "0xc280EbcD651d2a0C8D4bb49151062C9eEF55d370", 6, "US+", "USPLUS")
};
var coreTokens = {
  wcore: WETH9[ChainId.CORE],
  wcore_old: new ERC20Token(ChainId.CORE, "0x40375C92d9FAf44d2f9db9Bd9ba41a3317a2404f", 18, "WCORE", "WrappedCoreOld"),
  core: new ERC20Token(ChainId.CORE, "0x40375C92d9FAf44d2f9db9Bd9ba41a3317a2404f", 18, "CORE", "CORE"),
  ice: ICE[ChainId.CORE],
  score: new ERC20Token(ChainId.CORE, "0xA20b3B97df3a02f9185175760300a06B4e0A2C05", 18, "SCORE", "StakedCore", "https://obsidian.finance"),
  usdt: new ERC20Token(ChainId.CORE, "0x81bCEa03678D1CEF4830942227720D542Aa15817", 18, "USDTi", "Tether USD (ICE Bridge)"),
  usdc: new ERC20Token(ChainId.CORE, "0xD2683b22287E63D22928CBe4514003a92507f474", 18, "USDCi", "USD Coin (ICE Bridge)"),
  eth: new ERC20Token(ChainId.CORE, "0xeF6b7BC74C9354BCf2e3F2A068e4b0B5CDf08F29", 18, "ETH", "Ether"),
  bnb: new ERC20Token(ChainId.CORE, "0x12AA82525DEfF84777fa78578A68ceB854A85f43", 18, "BNB", "BNB"),
  huc: new ERC20Token(ChainId.CORE, "0x5EE2c2aE144218b52CF756c0907bA384C7E35fba", 18, "HUC", "Hobo Universe"),
  aicore: new ERC20Token(ChainId.CORE, "0x7621c97683A3b0499EC156bD257E44175e793bb1", 9, "AICore", "AI CORE TOKEN"),
  bcore: new ERC20Token(ChainId.CORE, "0xBFa14641bf0fE84dE3fcf3Bf227900af445f09C3", 18, "Bcore", "Big Core"),
  bcore_new: new ERC20Token(ChainId.CORE, "0x165290C62126083E31345Ebd74606eB562cDdD66", 9, "Bcore", "BigCore", "https://bigcoreproject.wixsite.com/bigcore"),
  kishu: new ERC20Token(ChainId.CORE, "0xb2172C92e22F09Bc7d15C4B1790c7704f8429d14", 18, "CKISHU", "Core Kishu"),
  gte: new ERC20Token(ChainId.CORE, "0xdA7dAA9a07ef5070dB671307fEa819c75d2D6cE3", 18, "GTE", "Goatge"),
  word: new ERC20Token(ChainId.CORE, "0xAA7912C028E058e4bD90Bcbb9fB41C27DbcC3245", 6, "WORD", "StarlyBooks"),
  cshib: new ERC20Token(ChainId.CORE, "0x751669F3dCE1ED1c449dE44889365E40F13ce57f", 9, "CoreShib", "CoreShib"),
  ctomb: new ERC20Token(ChainId.CORE, "0xC830a752eef79F2D66a36645A70fB0bA176b4Cea", 18, "CTOMB", "CoreTomb"),
  cshare: new ERC20Token(ChainId.CORE, "0x6501cCA79ca8D6F68784f2345c9a379951e30A05", 18, "CSHARE", "CoreShare"),
  lung: new ERC20Token(ChainId.CORE, "0xE8b0dF74192CCA9C4de66F23653476f6e6CD1d98", 18, "LUNG", "LunaGens"),
  hobo: new ERC20Token(ChainId.CORE, "0x25100C0083e8E53b1cb264E978522bd477011A0d", 18, "HOBO", "Hobo Universe"),
  coreshiba: new ERC20Token(ChainId.CORE, "0x84F0FDAA0a34B5F4a1144372072a706A4A8121A4", 18, "SHIBA", "ShibaCore"),
  usdtrain: new ERC20Token(ChainId.CORE, "0xFB59984Fd355C60064fcb191C0Ed817F1365eec0", 18, "USDTRAIN", "USDT Rain"),
  fsxm: new ERC20Token(ChainId.CORE, "0x5aE225fa6573903CA58E26Cd4171B87060CeEAA2", 18, "FSXM", "FlashX Max"),
  royale: new ERC20Token(ChainId.CORE, "0xA7c0B19645B653B4373E3592C84fce8C64D89E8F", 18, "ROYALE", "MemeRoyale"),
  cfee: new ERC20Token(ChainId.CORE, "0x7241C79C5Bf1C69eA6f0F8FAF2dFB30e37EF0820", 8, "CFEE", "Coffee Crypto"),
  usdtl0: new ERC20Token(ChainId.CORE, "0x900101d06A7426441Ae63e9AB3B9b0F63Be145F1", 6, "USDTc", "Tether USD (CORE Bridge)"),
  usdcl0: new ERC20Token(ChainId.CORE, "0xa4151B2B3e269645181dCcF2D426cE75fcbDeca9", 6, "USDCc", "USD Coin (CORE Bridge)"),
  btv: new ERC20Token(ChainId.CORE, "0xE8dEC1bFC7BF572D60403c609d6589715d2a23fC", 8, "BTV", "Bitvexa Network"),
  woof: new ERC20Token(ChainId.CORE, "0x5C44d3D2312AbA4d5F2406A98Bf374Bc76455092", 18, "WOOF", "WOOF"),
  miidas: new ERC20Token(ChainId.CORE, "0xcfd38184c30832917A2871695F91e5e61bBD41fF", 6, "Miidas", "Miidas NFT"),
  block: new ERC20Token(ChainId.CORE, "0xbFf24592345094DFA4d6f75aFF5BE79AbCbC9bD9", 8, "BLOCK", "BlockVerse"),
  ucore: new ERC20Token(ChainId.CORE, "0x496Bb259D0117E89B2e73433524e9838c3073e60", 18, "UCORE", "UnityCore"),
  crystal: new ERC20Token(ChainId.CORE, "0x005133502d02ddDe90D5f5283E4Af0F33A4a4db2", 18, "CRYSTAL", "CRYSTAL STONES"),
  maxi: new ERC20Token(ChainId.CORE, "0xDd5d49910c5D475c984EE891A928De6658d2042d", 18, "MAXL", "Maxi Protocol"),
  cmct: new ERC20Token(ChainId.CORE, "0x9EcC5C0179F02d2e8dBdaEbC8a6fC2B544ded9ba", 18, "CMCT", "CoinMarketCart"),
  but: new ERC20Token(ChainId.CORE, "0x40FFb9CCEA71c7E3b34593E83Aa362388f2fB4dC", 18, "BUT", "Butros"),
  bliz: new ERC20Token(ChainId.CORE, "0xd33b7081aEBA462333356757BFC78Bc000F6b1f4", 18, "BLIZ", "BLIZZARD CORP."),
  asi: new ERC20Token(ChainId.CORE, "0xEF51585D985bad6818C8aD7BA6D70cCf6D216c89", 18, "ASI", "Asi Core"),
  musk: new ERC20Token(ChainId.CORE, "0x154B5D670Ee6BA945B20A343d68Dff29720e9170", 18, "MUSK 2.0", "MUSK 2.0"),
  kigu: new ERC20Token(ChainId.CORE, "0xB0Da4ae4e087DCE159AE1C2d6C6f502e902516A1", 8, "KIGU", "Kigurumi"),
  cts: new ERC20Token(ChainId.CORE, "0x4225A3C57B2622f270D32C12e8135a095ED2dAf6", 18, "CTS", "Coretoshis"),
  kice: new ERC20Token(ChainId.CORE, "0xB946F9eB7556F41e825C535a052fbE23d3D04358", 18, "KICE", "ICE KING"),
  cBTC: new ERC20Token(ChainId.CORE, "0xb30692a602A22b7074FfA997b342eE7deEC6E85E", 18, "cBTC", "CoretoshisBTC"),
  hice: new ERC20Token(ChainId.CORE, "0xEcf2AdDc7267D65fBa77A8cFB23a9160B435fa31", 18, "HICE", "HOLD ICE"),
  gator: new ERC20Token(ChainId.CORE, "0xa386c445fb7219bFC37674D9fed086817dDF79BB", 9, "GATOR", "GATOR INU"),
  btcb: new ERC20Token(ChainId.CORE, "0x2297aEbD383787A160DD0d9F71508148769342E3", 8, "BTC.b", "Bitcoin"),
  youngparrot: new ERC20Token(ChainId.CORE, "0xf15fBa1E7112d680055962fB85a0C04550f24097", 18, "YPC", "YoungParrot"),
  pipi: new ERC20Token(ChainId.CORE, "0x3034802fc4C9A278D0886eD77fd3F79fd789c898", 18, "PIPI", "PIPI_LOL"),
  cdao: new ERC20Token(ChainId.CORE, "0x42077e348702f13Ea80CE6a6A38b8b60fbb37B5d", 18, "cDAO", "cDAO"),
  koci: new ERC20Token(ChainId.CORE, "0x6C0eEB2B306e1c7ec699E618031cA5C7Fb0363ab", 18, "KOCI", "KING OCICAT"),
  ceth: new ERC20Token(ChainId.CORE, "0xfA9975c36Ae3D983EE44c02fF7bB4AEe1A4493ac", 18, "cETH", "CoretoshisETH"),
  ripple: new ERC20Token(ChainId.CORE, "0x54e89D1C0D7B246ecaED450eF6d1d8C7B45EC3B6", 18, "3RPL", "3RIPLE COIN"),
  life: new ERC20Token(ChainId.CORE, "0x01F37fa58f316Cbb9B01Da817E5b61236f4CE82d", 18, "LIFE", "SHADOW LIFE"),
  freecore: new ERC20Token(ChainId.CORE, "0x2898aD472234C27163b99191d8D77bfC8bB7356D", 18, "FCD", "FreeCoreDaoCom"),
  asx: new ERC20Token(ChainId.CORE, "0xB28B43209d9de61306172Af0320f4f55e50E2f29", 18, "ASX", "ASX"),
  cctr: new ERC20Token(ChainId.CORE, "0x811af333E431f4a6283bFbB1E0cD244715FD9C54", 8, "CCTR", "Corecuties Refection"),
  pump: new ERC20Token(ChainId.CORE, "0x1483A469EF2C5B7DD2cb1B9174b01545C9a7fb69", 18, "PUMP", "Pumpkin Cat"),
  laugh: new ERC20Token(ChainId.CORE, "0xAd52f7099EeD91ddaA4614292f9090920aF00936", 18, "LAUGH", "LAUGH KOIN"),
  dogwif: new ERC20Token(ChainId.CORE, "0xc69b776f39006a7Fb864af5A673FffF0B2b51CFf", 18, "WIF", "DogWifCORE")
};
var xodexTokens = {
  wxodex: WETH9[ChainId.XODEX],
  ice: ICE[ChainId.XODEX],
  usdt: new ERC20Token(ChainId.XODEX, "0x54051D9DbE99687867090d95fe15C3D3E35512Ba", 18, "USDT", "Tether USD")
};
var shardeumTestnetTokens = {
  wshm: WETH9[ChainId.SHARDEUM_TEST],
  ice: ICE[ChainId.SHARDEUM_TEST],
  usdt: new ERC20Token(ChainId.SHARDEUM_TEST, "0x43891084581fD07Ee1189f3a2f04e51c26a95B77", 18, "USDT", "Tether USD")
};
var telosTokens = {
  wtlos: WETH9[ChainId.TELOS],
  ice: ICE[ChainId.TELOS],
  usdt: new ERC20Token(ChainId.TELOS, "0xc57F0eb99363e747D637B17BBdB4e1AB85e60631", 18, "USDT", "Tether USD"),
  usdt_m: new ERC20Token(ChainId.TELOS, "0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73", 18, "USDT", "Tether USD (Multichain.org)"),
  usdc_m: new ERC20Token(ChainId.TELOS, "0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b", 18, "USDC", "Circle USD (Multichain.org)")
};
var shimmerTestnetTokens = {
  wsmr: WETH9[ChainId.SHIMMER_TEST],
  ice: ICE[ChainId.SHIMMER_TEST],
  usdt: new ERC20Token(ChainId.SHIMMER_TEST, "0xc0E49f8C615d3d4c245970F6Dc528E4A47d69a44", 18, "USDT", "Tether USD")
};
var baseTokens = {
  weth: WETH9[ChainId.BASE],
  ice: ICE[ChainId.BASE],
  usdt: new ERC20Token(ChainId.BASE, "0xc0E49f8C615d3d4c245970F6Dc528E4A47d69a44", 18, "USDT", "Tether USD")
};
var shimmerTokens = {
  wsmr: WETH9[ChainId.SHIMMER],
  ice: ICE[ChainId.SHIMMER],
  usdt: new ERC20Token(ChainId.SHIMMER, "0xc0E49f8C615d3d4c245970F6Dc528E4A47d69a44", 18, "USDT", "Tether USD")
};
var blastTokens = {
  weth: WETH9[ChainId.BLAST],
  ice: ICE[ChainId.BLAST],
  usdb: new ERC20Token(ChainId.BLAST, "0x4300000000000000000000000000000000000003", 18, "USDT", "Blast USD"),
  ice_b: new ERC20Token(ChainId.BLAST, "0x24cb308a4e2F3a4352F513681Bd0c31a0bd3BA31", 18, "ICE_B", "IceCream[Blast]", "https://obsidian.finance")
};
var scrollTokens = {
  weth: WETH9[ChainId.SCROLL],
  ice: ICE[ChainId.SCROLL],
  usdt: new ERC20Token(ChainId.SCROLL, "0xc0E49f8C615d3d4c245970F6Dc528E4A47d69a44", 18, "USDT", "Tether USD")
};
var neonTokens = {
  wneon: WETH9[ChainId.NEON],
  ice: ICE[ChainId.NEON],
  usdt: new ERC20Token(ChainId.NEON, "0xc0E49f8C615d3d4c245970F6Dc528E4A47d69a44", 18, "USDT", "Tether USD"),
  chonk: new ERC20Token(ChainId.NEON, "0xF335ae40F387Bdc60477F0B306326A233D35227f", 18, "CHONK", "NeonChonk")
};
var qitmeerTokens = {
  wmeer: WETH9[ChainId.QITMEER],
  ice: new ERC20Token(ChainId.QITMEER, "0xd65CceCFf339e5680b1A1E7821421932cc2b114f", 18, "ROBIN", "IceCream", "https://obsidian.finance"),
  usdt: new ERC20Token(ChainId.QITMEER, "0x7D5a56742C082FcDfc240cd7D1775f00e059771F", 18, "USDT", "Tether USD")
};
var degenTokens = {
  wdegen: WETH9[ChainId.DEGEN],
  ice: ICE[ChainId.DEGEN],
  usdt: USD[ChainId.DEGEN]
};
var bobTokens = {
  weth: WETH9[ChainId.BOB]
};
var bobaTokens = {
  weth: WETH9[ChainId.BOBA],
  ice: ICE[ChainId.BOBA],
  usdt: new ERC20Token(ChainId.BOBA, "0x7D5a56742C082FcDfc240cd7D1775f00e059771F", 18, "USDT", "Tether USD"),
  boba: new ERC20Token(ChainId.BOBA, "0xa18bF3994C0Cc6E3b63ac420308E5383f53120D7", 18, "BOBA", "Boba Token"),
  usdc: new ERC20Token(ChainId.BOBA, "0x66a2A913e447d6b4BF33EFbec43aAeF87890FBbc", 6, "USDC", "USD Coin")
};

// src/index.ts
var bscTokens = {};

export { EMPTY_LIST, ICE, STABLE_COIN, USD, allTokens, baseTokens, bitgertTokens, blastTokens, bobTokens, bobaTokens, bscTokens, coreTokens, cronosTokens, cronoszkTokens, degenTokens, dogechainTokens, fuseTokens, getTokenByAddress, getTokensByChain, neonTokens, qitmeerTokens, scrollTokens, serializeTokens, shardeumTestnetTokens, shimmerTestnetTokens, shimmerTokens, telosTokens, unwrappedToken, xdcTokens, xodexTokens };
